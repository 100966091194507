import { dispatch } from "~/old-app/redux";
import { AuthActions } from "~/old-app/redux/auth-slice";
import { Response } from "../../types/api";
import { Api } from "../api-store";
import {
  Influencer,
  InfluencerOrderStatistics,
  InfluencerSignInBody,
  InfluencerSignInResponse,
  SendInfluencerRequestProps,
} from "./influencer-types";
import { Product, ProductsQueryParams } from "../product";
import { Coupon } from "../coupon";
import { Order } from "../order";
import { setCookie } from "~/old-app/utils/helpers/cookieManager";
import { CookieEnum } from "~/old-app/constants";

export const InfluencerApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    influencerProfile: builder.query<Response<Influencer>, void>({
      providesTags: ["influencer"],
      query: () => ({
        url: `/influencer/profile`,
        method: "GET",
      }),
    }),
    influencers: builder.query<Response<Influencer[]>, void>({
      providesTags: ["influencers"],
      query: () => ({
        url: `/influencer`,
        method: "GET",
      }),
    }),
    influencer: builder.query<Response<Influencer>, string | undefined>({
      providesTags: ["influencer"],
      query: (code) => ({
        url: `/influencer/${code}`,
        method: "GET",
      }),
    }),
    influencerCoupons: builder.query<
      Response<Coupon[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: ["influencer-coupons"],
      query: (params) => ({
        url: `/influencer/dashboard/my-coupons`,
        method: "GET",
        params,
      }),
    }),
    influencerProducts: builder.query<
      Response<Product[]>,
      ProductsQueryParams & { influencer_code?: string }
    >({
      providesTags: ["influencer-products"],
      query: ({ influencer_code, ...params }) => ({
        url: `/influencer/${influencer_code}/products`,
        method: "GET",
        params,
      }),

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { page, ...queryParams } = queryArgs as any;

        // const params = new URLSearchParams();
        // Object.keys(queryParams).forEach((key) => {
        //   params.append(
        //     key,
        //     queryParams[key as keyof ProductsQueryParams]?.toString() || ""
        //   );
        // });

        // const queryParamsString = params.toString();

        return {
          endpointName,
          ...queryParams,
        };
        // return `${endpointName}?${queryParamsString}`;
      },
      merge: (
        currentCache: Response<Product[]>,
        newItems: Response<Product[]>
      ) => {
        currentCache.response.data.push(...newItems.response.data);
        return currentCache as Response<Product[]>;
      },

      forceRefetch({ currentArg = {}, previousArg = {} }) {
        return Object.keys(currentArg).some(
          (key) => (currentArg as any)[key] !== (previousArg as any)[key]
        );
      },
    }),
    influencerOrders: builder.query<Response<Order[]>, ProductsQueryParams>({
      providesTags: (result, error, params) => [
        { type: "influencer-orders", ...params },
      ],
      query: ({ ...params }) => ({
        url: `/influencer/dashboard/orders`,
        method: "GET",
        params,
      }),
    }),
    influencerOrderStatistics: builder.query<
      Response<InfluencerOrderStatistics>,
      ProductsQueryParams
    >({
      providesTags: (result, error, params) => [
        { type: "influencer-orders-statistics", ...params },
      ],
      query: ({ ...params }) => ({
        url: `/influencer/dashboard/orders/statistics`,
        method: "GET",
        params,
      }),
    }),

    influencerSignIn: builder.mutation<
      Response<InfluencerSignInResponse>,
      InfluencerSignInBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/influencer/login`,
        method: "POST",
        body: JSON.stringify(data),
      }),
      transformResponse(res: Response<InfluencerSignInResponse>) {
        setCookie(CookieEnum.Token, res.response?.data?.token, {
          expires: 7,
        });

        dispatch(
          AuthActions.login({
            user: res.response?.data?.user,
            accessToken: res.response?.data?.token,
            role: "influencer",
          })
        );
        // window.location.reload();
        return res;
      },
    }),

    addToInfluencerCollection: builder.mutation<
      Response<InfluencerSignInResponse>,
      string | undefined
    >({
      invalidatesTags: ["products", "influencer-products"],
      query: (id) => ({
        url: `/influencer/dashboard/products/${id}/add`,
        method: "POST",
        body: JSON.stringify({}),
      }),
    }),
    removeFromInfluencerCollection: builder.mutation<
      Response<InfluencerSignInResponse>,
      string | undefined
    >({
      invalidatesTags: ["products", "influencer-products"],
      query: (id) => ({
        url: `/influencer/dashboard/products/${id}/remove`,
        method: "DELETE",
      }),
    }),

    updateInfluencerSettings: builder.mutation<
      Response<InfluencerSignInResponse>,
      { default_locale: string }
    >({
      invalidatesTags: ["products", "influencer-products"],
      query: (id) => ({
        url: `/influencer/dashboard/update-setting`,
        method: "PUT",
        body: JSON.stringify({}),
      }),
    }),

    influencerLogout: builder.mutation<Response<void>, void>({
      invalidatesTags: ["influencer"],
      query: () => ({
        url: `/influencer/dashboard/logout`,
        method: "POST",
      }),
      transformResponse(res: Response<void>) {
        dispatch(AuthActions.logout());
        window.location.reload();

        return res;
      },
    }),
    sendInfluencerRequest: builder.mutation<
      Response<void>,
      SendInfluencerRequestProps
    >({
      query: (body) => ({
        url: `/influencer/influencer-requests`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useInfluencerProfileQuery,
  useInfluencerCouponsQuery,
  useInfluencerProductsQuery,
  useInfluencerQuery,
  useInfluencersQuery,
  useInfluencerOrdersQuery,
  useInfluencerOrderStatisticsQuery,

  useInfluencerSignInMutation,
  useAddToInfluencerCollectionMutation,
  useRemoveFromInfluencerCollectionMutation,
  useInfluencerLogoutMutation,
  useUpdateInfluencerSettingsMutation,
  useSendInfluencerRequestMutation,
} = InfluencerApi;
