import { useState } from "react";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { ApiOptions } from "~/old-app/types/api";
import { ProductsQueryParams } from "./../product/product-types";
import {
  useInfluencerCouponsQuery,
  useInfluencerOrdersQuery,
  useInfluencerOrderStatisticsQuery,
  useInfluencerProductsQuery,
  useInfluencerProfileQuery,
  useInfluencerQuery,
  useInfluencersQuery,
} from "./influencer-api";

export const useInfluencerProfile = (options?: ApiOptions) => {
  const { data, isLoading, isFetching } = useInfluencerProfileQuery(
    undefined,
    options
  );
  return {
    influencer: data?.response?.data,
    isLoading,
    isFetching,
  };
};
export const useInfluencerOrderStatistics = (props?: ProductsQueryParams) => {
  const { data, isLoading, isFetching } = useInfluencerOrderStatisticsQuery(
    {
      ...props,
    },
    { skip: !props }
  );

  return {
    collection: data?.response?.data?.collection ?? "--",
    products: data?.response?.data?.products ?? "--",
    commission: data?.response?.data?.commission ?? "--",
    profit: data?.response?.data?.profit ?? "--",
    isLoading,
    isFetching,
  };
};

export const useInfluencers = (options?: ApiOptions) => {
  const { data, isLoading, isFetching } = useInfluencersQuery(
    undefined,
    options
  );
  return {
    influencers: data?.response?.data || [],
    isLoading,
    isFetching,
  };
};

export const useInfluencerCoupons = (
  params?: ProductsQueryParams,
  options?: ApiOptions
) => {
  const { data, isLoading, isFetching } = useInfluencerCouponsQuery(
    params,
    options
  );
  return {
    coupons: data?.response?.data || [],
    isLoading,
    isFetching,
  };
};

export const useInfluencerProducts = (
  props?: ProductsQueryParams,
  options?: ApiOptions
) => {
  const { selectedService } = useConfigSlice();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useInfluencerProductsQuery(
    {
      ...props,
      page: props?.page || 1,
      service_id: selectedService,
      influencer_code: props?.code,
    },
    { skip: options?.skip || !selectedService || !props?.code }
    // {
    //   ...props,
    //   service_id: selectedService,
    //   page,
    //   influencer_code: props?.code,
    // },
    // { skip: !selectedService || !props?.code }
  );

  const products = data?.response?.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== products.length;
  const meta = data?.response.meta;
  const fetchNextPage = () => setPage((prev) => prev + 1);

  return {
    products: data?.response.data || [],
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    meta,
    fetchNextPage,
  };
};
export const useInfluencerOrders = (props?: ProductsQueryParams) => {
  const { selectedService } = useConfigSlice();
  const { user } = useAuthSlice();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useInfluencerOrdersQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    { skip: !selectedService || !user?.code }
  );

  const orders = data?.response?.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== orders.length;

  return {
    orders: data?.response.data || [],
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
  };
};

export const useInfluencer = (code?: string) => {
  const { data, isLoading, isFetching } = useInfluencerQuery(code, {
    skip: !code,
  });
  return {
    influencer: data?.response?.data,
    isLoading,
    isFetching,
  };
};
